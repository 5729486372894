import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useStore } from "@store/index"
import Stack from "@components/ui/Stack/Stack"
import { initArray } from "@utils/numberUtils"
import { metricsDescriptors } from "@framework/constants/metrics"
import useToggle from "@framework/hooks/useToggle"
import Loader from "@components/ui/Loader/Loader"
import {
  AnalyticsCard,
  AnalyticsFilter,
  filterEqualityMapper,
  filterIncludesTypes,
} from "@framework/types/creativeAnalytics"
import { MetricDescription } from "@framework/types/metrics"
import ReportControlPanel from "../ReportControlPanel"
import ReportCard from "../components/ReportCard/ReportCard"
import { MetaCampaignReportData, ViewType } from "../types"
import mapper from "./mapper"
import Table from "../components/Table/Table"
import ReportDetailsModal from "../components/ReportDetails/ReportDetails"
import { generateCampaignReport } from "../mock"

import styles from "./General.module.scss"

type GeneralPageProps = {
  className?: string
}

const GeneralPage: React.FC<GeneralPageProps> = observer(({ className }) => {
  const {
    creativeAnalyticsStore: { adsCardsData, isLoading },
  } = useStore()

  const [view, setView] = React.useState<ViewType>("card")
  const [filters, setFilters] = React.useState<AnalyticsFilter[][]>([])
  const [filteredCards, setFilteredCards] = React.useState<AnalyticsCard[]>(
    adsCardsData.AdCreatives
  )
  const [activeMetrics, setActiveMetrics] = useState<MetricDescription[]>([])
  const filtersRef = React.useRef(filters)

  const modal = useToggle()

  const summary = Object.entries(adsCardsData.Summary).reduce(
    (previousValue, currentValue) => {
      const [key, value] = currentValue
      return { ...previousValue, [key]: value.Value }
    },
    {}
  )

  const onAppliedFilters = (filters: AnalyticsFilter[][]) => {
    if (filters.length === 0) {
      setFilteredCards(adsCardsData.AdCreatives)
      setFilters([])
      filtersRef.current = []
      return
    }
    setFilters(filters)
  }

  const onMetricsFilterChange = (metrics: string[]) => {
    const filteredMetricsDescriptors = metricsDescriptors.filter((md) =>
      metrics.includes(md.name)
    )
    setActiveMetrics(filteredMetricsDescriptors)
  }

  useEffect(() => {
    setFilteredCards(adsCardsData.AdCreatives)
  }, [adsCardsData])

  useEffect(() => {
    let result = filteredCards
    if (filtersRef.current.length > filters.length) {
      filtersRef.current = filters
      result = adsCardsData.AdCreatives
    }
    if (filters.length === 0) {
      return
    }
    filters.forEach((filter) => {
      const firstFilter = filter[0]
      const secondFilter = filter[1]
      if (
        firstFilter.value === "campaigns" ||
        firstFilter.value === "adSets" ||
        firstFilter.value === "creativeType"
      ) {
        const thirdFilters = filter.filter((f) => f.key === 3)
        if (filterIncludesTypes[secondFilter.value] === "Includes") {
          result = result.filter((card) => {
            if (firstFilter.value === "campaigns")
              return thirdFilters.find((f) => f.value === card.CampaignName)
            if (firstFilter.value === "adSets")
              return thirdFilters.find((f) => f.value === card.AdsetName)
            return thirdFilters.find((f) => f.value === card.Type)
          })
        }
        if (filterIncludesTypes[secondFilter.value] === "Does not include") {
          result = result.filter((card) => {
            if (firstFilter.value === "campaigns")
              return !thirdFilters.find((f) => f.value === card.CampaignName)
            if (firstFilter.value === "adSets")
              return !thirdFilters.find((f) => f.value === card.AdsetName)
            return !thirdFilters.find((f) => f.value === card.Type)
          })
        }
      } else {
        const firstFilterValue = firstFilter.value
        const secondFilterValue = secondFilter.value
        const thirdFilterValue = Number(filter[2].value)
        const filterEqualityMapperType = filterEqualityMapper(secondFilterValue)
        result = result.filter((card) => {
          const metric = card[firstFilterValue]
          if (typeof metric === "object") {
            return filterEqualityMapperType(metric.Value, thirdFilterValue)
          }
          return false
        })
      }
    })
    filtersRef.current = filters
    setFilteredCards(result)
  }, [filters, adsCardsData])

  return (
    <div className={clsx(styles.root, className)}>
      <Stack direction="column" gutter="20" align="stretch">
        <ReportControlPanel
          view={view}
          onViewChange={setView}
          onAppliedFilters={onAppliedFilters}
          onMetricsFilter={onMetricsFilterChange}
        />

        {isLoading && <Loader />}

        {view === "table" && !isLoading && (
          <Table
            className={styles.table}
            mapper={mapper}
            data={filteredCards}
            summary={summary}
          />
        )}

        {view === "card" && !isLoading && (
          <div className={styles.grid}>
            {filteredCards.map((card) => (
              <ReportCard
                data={card}
                metrics={activeMetrics}
                onClick={modal.handleToggle}
                key={card.Id}
              />
            ))}
          </div>
        )}
      </Stack>

      <ReportDetailsModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
      />
    </div>
  )
})

export default GeneralPage

const reportsMock = initArray<MetaCampaignReportData>(111, (idx) =>
  generateCampaignReport(idx)
)

const summaryReport = generateCampaignReport(999, false)
