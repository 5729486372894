import React from "react"
import clsx from "clsx"
import get from "lodash/get"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import { MetricDescription } from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import Image from "@components/ui/Image/Image"
import useToggle from "@framework/hooks/useToggle"
import { MetaCampaignReportData } from "@pages/MetaAdsChannel/types"
import MetricValueChip from "../MetricValueChip"
import ReportTitle from "../ReportTitle"
import KPITrendModal from "../KPITrendChart/KPITrendModal"

import styles from "./ReportCard.module.sass"

interface ReportCardProps {
  data: MetaCampaignReportData
  metrics?: MetricDescription[]
  active?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler
}

export const ReportCard: React.FC<ReportCardProps> = ({
  data,
  active = false,
  disabled = false,
  onClick,
  metrics,
}) => {
  const modal = useToggle()
  return (
    <Box
      className={clsx(styles.root, {
        [styles.active]: active,
        [styles.disabled]: disabled,
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
      elevation={1}
    >
      <div className={styles.header}>
        <ReportTitle textClassName={styles.link}>{data.AdsetName}</ReportTitle>

        <Button variant="ghost" color="secondary" size="small">
          <Icon name="three-dots" />
        </Button>
      </div>

      {/* <StatusBadge status={data.status} /> */}

      <div className={styles.previewContainer}>
        {data.Type === "IMAGE" ? (
          <Image
            src={data.ThumbnailURL}
            className={styles.image}
            alt="Preview"
          />
        ) : data.Type === "VIDEO" ? (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe src={data.VideoURL} className={styles.image} />
        ) : null}

        <Typography
          className={styles.views}
          type="caption3"
          color="black03Color"
          title="People are watching"
        >
          <Icon name="eye" />
          {/* {numberWithCommas(data.totalViews ?? 0)} */}
        </Typography>
      </div>

      <Stack
        className={styles.metricsContainer}
        gutter="4"
        direction="column"
        align="stretch"
      >
        <Stack direction="row" justify="space-between" align="center">
          <Typography type="h5" weight="bold">
            Metrics
          </Typography>

          <Button
            size="small"
            color="primary"
            variant="outlined"
            before={<Icon name="statistics" />}
            onClick={(e) => {
              modal.setOpened(true)
              e.stopPropagation()
            }}
          >
            KPI Trends
          </Button>
        </Stack>

        {metrics?.map((metric) => {
          const value = get(data, metric.name, null)
          return (
            <Stack
              direction="row"
              justify="space-between"
              align="center"
              key={metric.name}
            >
              <Typography type="subhead1">{metric.label}</Typography>

              <Typography type="caption3">
                <MetricValueChip
                  className={styles.metricChip}
                  value={value}
                  metric={metric}
                />
              </Typography>
            </Stack>
          )
        }) ?? <NoData />}
      </Stack>

      {/* TODO move to root page */}
      <KPITrendModal
        open={modal.isOpened}
        onClose={() => modal.setOpened(false)}
      />
    </Box>
  )
}

export default ReportCard
