import { PerformanceReport } from "@framework/types/metrics"

export type ViewType = "table" | "card"

// types can be change during API integration
export const metaCampaignStatusNames = [
  "INACTIVE",
  "IN_REVIEW",
  "ACTIVE",
  "LOW_PERFORMANCE",
  "FAILED",
] as const

export type MetaCampaignStatus = (typeof metaCampaignStatusNames)[number]

export type MetaCampaignReportData = {
  Name: string
  Id: string
  AdType: string
  VideoURL: string
  ThumbnailURL: string
  CampaignName: string
  AdsetName: string
  status?: MetaCampaignStatus
  totalViews?: number
  Type: string
} & PerformanceReport

export type MetaCampaignReportDataV1 = PerformanceReport
