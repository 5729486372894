import React from "react"
import { ScrollSync } from "react-scroll-sync"
import clsx from "clsx"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { observer } from "mobx-react-lite"
import { List, ListRowProps } from "react-virtualized"

import Header from "./Header"
import Row from "./Row"
import { ColumnMapper } from "./types"

import styles from "./Table.module.scss"
import Footer from "./Footer"

const SINGLE_ROW_HEIGHT = 48

const STATIC_BEFORE = 1

interface TableProps<T extends object> {
  className?: string
  mapper: ColumnMapper<T>[]
  data?: T[]
  summary?: T
  totalCount?: number
  lastUpdate?: number
}

const Table = observer(
  <T extends object>({
    className,
    mapper,
    data,
    summary,
    totalCount = data?.length ?? 0,
    lastUpdate,
  }: TableProps<T>) => {
    const infiniteLoaderRef = React.useRef<InfiniteLoader>(null)
    const hasMountedRef = React.useRef(false)

    const handleLoadMore = async (startIndex: number, stopIndex: number) => {
      // TODO add loader if needed
    }

    const isRowLoaded = (idx: number) => !!data?.[idx]

    const renderRow = ({ index, style, key }: ListRowProps) => {
      const item = data?.[index]
      return (
        <div key={key} style={style}>
          <Row data={item} mapper={mapper} staticBefore={STATIC_BEFORE} />
        </div>
      )
    }

    React.useEffect(() => {
      if (hasMountedRef.current && infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(true)
      }
      hasMountedRef.current = true
    }, [lastUpdate])

    return (
      <ScrollSync>
        <div className={clsx(styles.root, className)}>
          <Header
            mapper={mapper}
            staticBefore={STATIC_BEFORE}
            className={styles.headerContainer}
          />

          <div className={clsx(styles.body)}>
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={isRowLoaded}
              itemCount={totalCount}
              loadMoreItems={handleLoadMore}
              threshold={50}
              minimumBatchSize={50}
            >
              {({ ref }) => (
                <AutoSizer>
                  {(size) => (
                    <List
                      className={styles.list}
                      overscanCount={3}
                      height={size.height}
                      width={size.width}
                      rowCount={totalCount}
                      rowHeight={SINGLE_ROW_HEIGHT}
                      rowRenderer={renderRow}
                      ref={ref}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>

          {/* {summary != null && ( */}
          {/*  <Footer */}
          {/*    mapper={mapper} */}
          {/*    staticBefore={STATIC_BEFORE} */}
          {/*    className={styles.headerContainer} */}
          {/*    data={summary} */}
          {/*  /> */}
          {/* )} */}
        </div>
      </ScrollSync>
    )
  }
)

export default Table
