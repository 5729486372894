import React from "react"
import clsx from "clsx"
import get from "lodash/get"
import { observer } from "mobx-react-lite"

import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Box from "@components/ui/Box/Box"
import { MetricDescription } from "@framework/types/metrics"
import NoData from "@components/ui/NoData/NoData"
import { MetaCampaignReportData } from "@pages/MetaAdsChannel/types"
import ScrollContainer from "@components/ui/ScrollContainer/ScrollContainer"
import MetricCard from "./MetricCard"

import styles from "./DetailsSection.module.sass"

interface DetailsSectionProps {
  data: MetaCampaignReportData
  coreMetrics?: MetricDescription[]
  otherMetrics?: MetricDescription[]
}

export const DetailsSection: React.FC<DetailsSectionProps> = observer(
  ({ data, coreMetrics, otherMetrics }) => (
    <Box className={clsx(styles.root)} elevation={1}>
      <section className={styles.header}>
        <Typography type="h4" color="black100Color">
          Details
        </Typography>
      </section>

      <ScrollContainer className={styles.bodyContainer} type="secondary">
        <section className={styles.body}>
          <Stack direction="column" align="stretch" gutter="8">
            <Typography type="h6" color="black70Color" upperCase>
              General
            </Typography>

            <Stack direction="column" align="stretch" gutter="0">
              <Typography type="caption3" color="black70Color">
                Campaign
              </Typography>
              <Typography type="body2" color="black100Color">
                {data.CampaignName}
              </Typography>
            </Stack>

            <Stack direction="column" align="stretch" gutter="0">
              <Typography type="caption3" color="black70Color">
                Ad Set Name
              </Typography>
              <Typography type="body2" color="black100Color">
                {data.AdsetName}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="column" align="stretch" gutter="8">
            <Typography type="h6" color="black70Color" upperCase>
              Core
            </Typography>

            <div className={styles.grid}>
              {coreMetrics?.map((metric) => {
                const value = get(data, metric.name, null)
                return (
                  <MetricCard
                    key={metric.name}
                    value={value}
                    metric={metric}
                    withScale
                  />
                )
              }) ?? <NoData />}
            </div>
          </Stack>

          <Stack direction="column" align="stretch" gutter="8">
            <Typography type="h6" color="black70Color" upperCase>
              Analytics
            </Typography>

            <div className={styles.grid}>
              {otherMetrics?.map((metric) => {
                const value = get(data, metric.name, null)
                return (
                  <MetricCard value={value} metric={metric} key={metric.name} />
                )
              }) ?? <NoData />}
            </div>
          </Stack>
        </section>
      </ScrollContainer>
    </Box>
  )
)

export default DetailsSection
