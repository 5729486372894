import { AxiosResponse } from "axios"
import config from "@root/config"
import HttpService from "@services/http.service"
import { ID } from "@framework/types/types"
import { AnalyticsCard } from "@framework/types/creativeAnalytics"
import { PerformanceReportV1 } from "@framework/types/metrics"

export interface ADSAnalyticsResponse {
  data: {
    AdCreatives: AnalyticsCard[]
    Summary: PerformanceReportV1
  }
}

class CreativeAnalyticsAPI extends HttpService {
  getADSCards = (
    accountId: ID,
    params: { from: string; to: string }
  ): Promise<AxiosResponse<ADSAnalyticsResponse>> =>
    this.get(`property/${accountId}/meta/ad-creatives`, params)
}

export default new CreativeAnalyticsAPI({
  API_URL: config.API_ROOT,
})
