/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import styles from "./ListItem.module.scss"

export interface ListItemProps {
  onClick?: React.MouseEventHandler
  title?: React.ReactNode
  subtitle?: React.ReactNode
  before?: React.ReactNode
  after?: React.ReactNode
  disabled?: boolean
  displayDisabled?: boolean
  children?: React.ReactNode
  isSubItem?: boolean
}

const BaseListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      title,
      children,
      subtitle,
      before,
      after,
      disabled = false,
      displayDisabled = false,
      onClick,
      isSubItem,
    },
    ref
  ) => (
    <li
      ref={ref}
      onClick={onClick}
      tabIndex={-1}
      className={clsx(styles.root, {
        [styles.disabled]: disabled,
        [styles.displayDisabled]: disabled || displayDisabled,
        [styles.subItem]: isSubItem,
      })}
    >
      {title != null && <div className={styles.title}>{title}</div>}

      <div className={styles.body}>
        {before != null && <span className={styles.before}>{before}</span>}

        <span className={styles.label}>{children}</span>

        {after != null && <span className={styles.after}>{after} </span>}
      </div>

      {subtitle != null && <div className={styles.subtitle}>{subtitle}</div>}
    </li>
  )
)

export default BaseListItem
