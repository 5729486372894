import { numberWithCommas } from "@components/utils/numberUtils"
import {
  moneyFormatter,
  preciseMoneyFormatter,
  precisePercentageFormatter,
} from "@services/utils"
import { MetricDescription, MetricNameType } from "@framework/types/metrics"
import { negativeTransformDirection } from "@components/ui/Typography/Trend/utils"

type MetricsDescriptorsMap = Record<MetricNameType, MetricDescription>

export const metricsDescriptorsMap: MetricsDescriptorsMap = {
  AOV: {
    name: "AOV",
    label: "AOV",
    shortLabel: "AOV",
    description:
      "Average Order Value, the average dollar amount spent each time by placing an ad",
    formatter: moneyFormatter,
  },
  AverageCpc: {
    name: "AverageCpc",
    label: "Avg CPC",
    shortLabel: "Avg CPC",
    description: "Cost per click (in USD)",
    trendFormat: negativeTransformDirection,
    formatter: preciseMoneyFormatter,
  },
  Clicks: {
    name: "Clicks",
    label: "Clicks",
    shortLabel: "Clicks",
    description: "Number of simple clicks of an add",
    formatter: numberWithCommas,
  },
  ConversionRate: {
    name: "ConversionRate",
    label: "Conversion Rate%",
    shortLabel: "Conv. Rate%",
    description: "The average number of conversions per ad interaction (in %)",
    formatter: (value: number | string) =>
      precisePercentageFormatter(Number(value) * 100),
    normalizeForUI: (value) => Number(value) * 100,
    normalizeForAPI: (value) => Number(value) / 100,
  },
  Conversions: {
    name: "Conversions",
    label: "Conversions",
    shortLabel: "Conv.",
    description:
      "The total number of main conversion events (i.e. Transactions, Sign Up, or Lead etc) that occurred after user interacted with an ad",
    formatter: numberWithCommas,
  },
  Cost: {
    name: "Cost",
    label: "Spend",
    shortLabel: "Spend",
    description: "Cost used to date (in USD)",
    formatter: moneyFormatter,
  },
  CostConv: {
    name: "CostConv",
    label: "Cost/Conversion",
    shortLabel: "Cost/Conv.",
    description:
      "The average amount that has been charged for a conversion from an ad.",
    trendFormat: negativeTransformDirection,
    formatter: preciseMoneyFormatter,
    unit: {
      prefix: "$",
      decimalScale: 2,
    },
  },
  Cpm: {
    name: "Cpm",
    label: "CPM",
    shortLabel: "CPM",
    description: "Cost per 1000 impressions (in USD)",
    formatter: preciseMoneyFormatter,
  },
  Ctr: {
    name: "Ctr",
    label: "CTR%",
    shortLabel: "CTR%",
    description:
      "A ratio showing how often (in %) people who see an ad listing end up clicking it",
    formatter: (value: number | string) =>
      precisePercentageFormatter(Number(value) * 100, 1),
    normalizeForUI: (value) => Number(value) * 100,
    normalizeForAPI: (value) => Number(value) / 100,
    unit: {
      suffix: "%",
      decimalScale: 1,
    },
  },
  Impressions: {
    name: "Impressions",
    label: "Impressions",
    shortLabel: "Impressions",
    description: "Number of times the ad was shown",
    formatter: numberWithCommas,
  },
  /**
   * @deprecated
   */
  ResClickRate: {
    name: "ResClickRate",
    label: "Res. Click %",
    shortLabel: "Res. Click %",
    description: "Short description of metric",
    formatter: (value: number | string) =>
      precisePercentageFormatter(Number(value) * 100, 1),
    normalizeForUI: (value) => Number(value) * 100,
    normalizeForAPI: (value) => Number(value) / 100,
  },
  Revenue: {
    name: "Revenue",
    label: "Revenue",
    shortLabel: "Revenue",
    description: "Revenue",
    formatter: moneyFormatter,
    unit: {
      prefix: "$",
      decimalScale: 0,
    },
  },
  Roas: {
    name: "Roas",
    label: "ROAS",
    shortLabel: "ROAS",
    description:
      "Amount of revenue your business earns for each dollar it spends on advertising",
    formatter: preciseMoneyFormatter,
    unit: {
      prefix: "$",
      decimalScale: 2,
    },
  },
  SearchImpressionShare: {
    name: "SearchImpressionShare",
    label: "Impression Share",
    shortLabel: "Impr. Share",
    description:
      "The percentage of impressions that your ads receive compared to the total number of impressions that your ads could get.",
    formatter: (value: number | string) =>
      precisePercentageFormatter(Number(value) * 100, 1),
    normalizeForUI: (value) => Number(value) * 100,
    normalizeForAPI: (value) => Number(value) / 100,
  },
  Holdplay: {
    name: "Holdplay",
    label: "Holdplay",
    shortLabel: "Holdplay",
    description:
      "The number of times your video was watched for at least 10 seconds.",
    formatter: numberWithCommas,
    normalizeForUI: (value) => Number(value),
    normalizeForAPI: (value) => Number(value),
  },
  Thumbstop: {
    name: "Thumbstop",
    label: "Thumbstop",
    shortLabel: "Thumbstop",
    description:
      "The number of times users stopped scrolling to watch at least 3 seconds of your video.",
    formatter: numberWithCommas,
    normalizeForUI: (value) => Number(value),
    normalizeForAPI: (value) => Number(value),
  },
}

export const metricsDescriptors = Object.values(metricsDescriptorsMap)

export const getMetricDescriptor = (value: MetricNameType | string) =>
  metricsDescriptorsMap[value as MetricNameType] ?? null

export const renderMetricOption = (value: string | number) =>
  getMetricDescriptor(value as MetricNameType)?.label ?? value
