import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"

import Typography from "@components/ui/Typography/Typography"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import Image from "@components/ui/Image/Image"
import {
  initArray,
  numberWithCommas,
  randomNumberMinMax,
} from "@utils/numberUtils"
import { MetaCampaignReportData } from "@pages/MetaAdsChannel/types"
import AreaChart from "@components/ui/Charts/AreaChart/AreaChart"
import Stack from "@components/ui/Stack/Stack"

import styles from "./MediaSection.module.sass"

const totalPointsMock = 10

interface MediaSectionProps {
  data: MetaCampaignReportData
}

export const MediaSection: React.FC<MediaSectionProps> = observer(
  ({ data }) => {
    const labelsMock = React.useMemo(
      () =>
        initArray(totalPointsMock, (idx) =>
          moment("00:00", "HH:mm")
            .add(idx * 15, "minutes")
            .format("HH:mm")
        ),
      []
    )

    const pointsMock = initArray(10, (idx) => randomNumberMinMax(0, 100))

    return (
      <Box className={clsx(styles.root)} elevation={1}>
        <div className={styles.mediaContainer}>
          <Image
            src={data.ThumbnailURL}
            className={styles.image}
            alt="Preview"
          />

          <Typography
            className={styles.views}
            type="body1"
            color="black03Color"
            title="People are watching"
          >
            <Icon name="eye" />
            {numberWithCommas(data.totalViews ?? 0)}
          </Typography>
        </div>

        <div className={styles.trendContainer}>
          <Stack gutter="4">
            <Typography type="h4" weight="bold">
              Trend
            </Typography>
            <Typography type="caption2" color="black60Color">
              Video duration: {labelsMock[labelsMock.length - 1]}
            </Typography>
          </Stack>

          <div className={styles.chart}>
            <AreaChart labels={labelsMock} data={pointsMock} />
          </div>
        </div>
      </Box>
    )
  }
)

export default MediaSection
