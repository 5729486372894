import React, { useEffect, useState, useMemo } from "react"
import { observer } from "mobx-react-lite"

import Stack from "@components/ui/Stack/Stack"
import { Button, ButtonGroup } from "@components/ui/Button"
import Box from "@components/ui/Box/Box"
import Icon from "@components/ui/Icon/Icon"
import { getCurrentPeriodOptions } from "@store/reports/utils"
import ButtonDatePicker from "@components/ui/DatePicker/ButtonDatePicker"
import IconButton from "@components/ui/Button/IconButton"
import SimpleSelect from "@components/ui/DropDown/SimpleSelect"
import Typography from "@components/ui/Typography/Typography"
import TextField from "@components/ui/TextField/TextField"
import SimpleMultiselect from "@components/ui/DropDown/SimpleMultiselect"
import useOptionSearchList from "@framework/prototypes/useOptionsSearchList"
import Chip from "@components/ui/Chip/Chip"
import { DateRangeOption } from "@components/ui/DatePicker/types"
import { useStore } from "@root/store"
import { apiDateFormatter } from "@services/utils"
import {
  AnalyticsFilter,
  creativeTypes,
  filterEqualityTypes,
  filterIncludesTypes,
  filterOptions,
  metricOptions,
} from "@framework/types/creativeAnalytics"
import { ViewType } from "./types"
import styles from "./ReportControlPanel.module.scss"

interface ReportControlPanelProps {
  view?: ViewType
  onAppliedFilters: (filters: AnalyticsFilter[][]) => void
  onMetricsFilter?: (metrics: string[]) => void
  onViewChange?: (view: ViewType) => void
}

const filterOptionsArray = Object.entries(filterOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const filterOptionsMetricsArray = Object.keys(filterEqualityTypes)
const filterOptionsMainArray = Object.keys(filterIncludesTypes)
const creativeTypeOptionsArray = Object.keys(creativeTypes)

const defaultMetrics: string[] = [
  "AOV",
  "Clicks",
  "Impressions",
  "Conversions",
  "CostConv",
  "ConversionRate",
  "Revenue",
]
const metricsOptionsArray = Object.entries(metricOptions).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

const ReportControlPanel: React.FC<ReportControlPanelProps> = observer(
  ({ view = "card", onAppliedFilters, onMetricsFilter, onViewChange }) => {
    const {
      accountStore: { accountId },
      creativeAnalyticsStore: { getADSCards, adsCardsData, isLoading },
    } = useStore()
    const datePickerOptions = useMemo(() => getCurrentPeriodOptions(), [])

    const [campaignsList, setCampaignsList] = useState<string[]>([])
    const [adSetsList, setAdSetsList] = useState<string[]>([])
    const [period, setPeriod] = useState(datePickerOptions[0])
    const [showFilters, setShowFilters] = useState(false)
    const [showMetrics, setShowMetrics] = useState(false)
    const [filterValue, setFilterValue] = useState<string>("")
    const [filterOptionsMetrics, setFilterOptionsMetrics] = useState<string>("")
    const [filterOptionsMain, setFilterOptionsMain] = useState<string>("")
    const [filterTextValue, setFilterTextValue] = useState<string>("")
    const [creativeTypeOptions, setCreativeTypeOptions] = useState<string[]>([])
    const [filterCampaignsValue, setFilterCampaignsValue] = useState<string[]>(
      []
    )
    const [filterAdsetsValue, setFilterAdsetsValue] = useState<string[]>([])
    const [metricOptionsValues, setMetricOptionsValues] =
      useState<string[]>(defaultMetrics)

    const metricsOptions = useOptionSearchList({
      list: metricsOptionsArray ?? [],
    })

    const filterOptions = useOptionSearchList({
      list: filterOptionsArray ?? [],
    })

    const [allFiltersList, setAllFiltersList] = useState<AnalyticsFilter[]>([])
    const [appliedFiltersStrings, setAppliedFiltersString] = useState<string[]>(
      []
    )
    const [appliedFilters, setAppliedFilters] = useState<AnalyticsFilter[][]>(
      []
    )

    const handleFilterValue = (value: string) => {
      setFilterValue(value)
      setAllFiltersList([{ key: 1, value }])
      setFilterOptionsMain("")
      setFilterOptionsMetrics("")
      setCreativeTypeOptions([])
      setFilterCampaignsValue([])
      setFilterAdsetsValue([])
      setMetricOptionsValues([])
      setFilterTextValue("")
    }

    useEffect(() => {
      if (!accountId) return
      getADSCards(accountId, {
        from: apiDateFormatter(period.range[0]),
        to: apiDateFormatter(period.range[1]),
      })
    }, [accountId])

    useEffect(() => {}, [
      filterValue,
      filterOptionsMain,
      filterOptionsMetrics,
      creativeTypeOptions,
      filterAdsetsValue,
      filterCampaignsValue,
      metricOptionsValues,
      allFiltersList,
    ])

    useEffect(() => {
      onAppliedFilters(appliedFilters)
    }, [appliedFilters])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [metricOptionsValues])

    useEffect(() => {
      if (onMetricsFilter) onMetricsFilter(metricOptionsValues)
    }, [])

    useEffect(() => {
      setFilterValue("")
      setAllFiltersList([])
      setFilterOptionsMain("")
      setFilterOptionsMetrics("")
      setCreativeTypeOptions([])
      setFilterCampaignsValue([])
      setFilterAdsetsValue([])
      setFilterTextValue("")
    }, [])

    useEffect(() => {
      const campaigns: Set<string> = new Set()
      const adSets: Set<string> = new Set()
      if (adsCardsData.AdCreatives.length) {
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.CampaignName) {
            campaigns.add(c.CampaignName)
          }
        })
        adsCardsData.AdCreatives.forEach((c) => {
          if (c.AdsetName) {
            adSets.add(c.AdsetName)
          }
        })
      }
      setCampaignsList(Array.from(campaigns))
      setAdSetsList(Array.from(adSets))
    }, [adsCardsData])

    const handleFilterOptionsMain = (value: string) => {
      setFilterOptionsMain((prevState) => {
        setAllFiltersList((prev) => {
          let res = []
          if (prev.find((i) => i.key === 2)) {
            res = prev.filter((it) => it.value !== prevState)
            return [...res, { key: 2, value }]
          }
          return [...prev, { key: 2, value }]
        })
        return value
      })
    }

    const handleFilterOptionsMetrics = (value: string) => {
      setFilterOptionsMetrics((prevState) => {
        setAllFiltersList((prev) => {
          let res = []
          if (prev.find((i) => i.key === 2)) {
            res = prev.filter((it) => it.value !== prevState)
            return [...res, { key: 2, value }]
          }
          return [...prev, { key: 2, value }]
        })
        return value
      })
    }

    const handleThirdFilter = (value: string, type: string) => {
      let val: string = ""
      if (type === "creativeType") {
        val = value
        setCreativeTypeOptions((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
      }
      if (type === "adsetsType") {
        val = value
        setFilterAdsetsValue((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
      }
      if (type === "campaignType") {
        val = value
        setFilterCampaignsValue((prevState) =>
          prevState.includes(value)
            ? prevState.filter((it) => it !== value)
            : [...prevState, value]
        )
      }
      setAllFiltersList((prev) => {
        let res = []
        if (prev.find((i) => i.value === value && i.key === 3)) {
          res = prev.filter((it) => it.value !== value)
          return res
        }

        return [...prev, { key: 3, value: val }]
      })
    }

    const handleFilterTextValue = (value: string) => {
      setFilterTextValue(value)
      setAllFiltersList((prev) => {
        let res = []
        if (prev.findIndex((i) => i.key === 3)) {
          res = prev.filter((it) => it.key !== 3)
          return [...res, { key: 3, value }]
        }
        return [...prev, { key: 3, value }]
      })
    }

    const handleMetricsOptions = (value: string) => {
      setMetricOptionsValues((prev) =>
        prev.includes(value)
          ? prev.filter((it) => it !== value)
          : [...prev, value]
      )
    }

    const handleApplyFilter = () => {
      if (allFiltersList.length < 3) return
      const res = allFiltersList
        .sort((a, b) => a.key - b.key)
        .map((it) => it.value)
        .join(" ")
      setAppliedFiltersString((prev) => [...prev, res])
      setAppliedFilters((prev) => [...prev, allFiltersList.sort()])
      setShowFilters(false)
    }

    const handleRemoveFilter = (filter: string, index: number) => {
      setAppliedFiltersString((prev) => prev.filter((it) => it !== filter))
      setAppliedFilters((prev) => prev.filter((it, i) => i !== index))
    }

    const handleSetPeriod = (period: DateRangeOption) => {
      setPeriod(period)
      if (!accountId) return
      getADSCards(accountId, {
        from: apiDateFormatter(period.range[0]),
        to: apiDateFormatter(period.range[1]),
      })
    }

    return (
      <Box>
        {!isLoading && (
          <Stack
            className={styles.reportControlPanel}
            direction="row"
            gutter="8"
            justify="space-between"
          >
            <Stack direction="row" gutter="8">
              <Button color="secondary" before={<Icon name="refresh" />}>
                Sync
              </Button>
              <ButtonDatePicker
                range={period}
                onRangeChange={handleSetPeriod}
                rangeOptions={datePickerOptions}
              />
              <Button
                color="secondary"
                before={<Icon name="filter" />}
                onClick={() =>
                  setShowFilters((prev) => {
                    setShowMetrics(false)
                    return !prev
                  })
                }
                after={
                  allFiltersList.length > 0 ? (
                    <span className={styles.filtersCountIcon}>
                      <Typography type="body2" color="light">
                        {appliedFiltersStrings.length}
                      </Typography>
                    </span>
                  ) : null
                }
              >
                Filters
              </Button>
              <Button color="secondary" before={<Icon name="stack" />}>
                Groups
              </Button>
              <Button
                color="secondary"
                before={<Icon name="eye" />}
                onClick={() =>
                  setShowMetrics((prev) => {
                    setShowFilters(false)
                    return !prev
                  })
                }
              >
                Metrics
              </Button>
            </Stack>

            {showFilters && (
              <div className={styles.filterMenu}>
                <Typography type="body1">Where </Typography>
                <SimpleSelect
                  className={styles.select}
                  withSearch
                  titleItems
                  onSelect={handleFilterValue}
                  value={filterValue}
                  {...filterOptions}
                />
                {filterValue === "campaigns" ||
                filterValue === "adSets" ||
                filterValue === "creativeType" ? (
                  <SimpleSelect
                    className={styles.select}
                    options={filterOptionsMainArray}
                    onSelect={handleFilterOptionsMain}
                    value={filterOptionsMain}
                    renderValue={(value) => filterIncludesTypes[value]}
                  />
                ) : null}

                {filterValue !== "campaigns" &&
                filterValue !== "adSets" &&
                filterValue !== "creativeType" ? (
                  <SimpleSelect
                    className={styles.select}
                    options={filterOptionsMetricsArray}
                    onSelect={handleFilterOptionsMetrics}
                    value={filterOptionsMetrics}
                    renderValue={(value) => filterEqualityTypes[value]}
                  />
                ) : null}

                {filterValue !== "campaigns" &&
                filterValue !== "adSets" &&
                filterValue !== "creativeType" ? (
                  <TextField
                    value={filterTextValue}
                    onChange={(e) => handleFilterTextValue(e.target.value)}
                    className={styles.select}
                    placeholder="Value"
                    type="number"
                    step="0.01"
                  />
                ) : null}

                {filterValue === "creativeType" ? (
                  <SimpleMultiselect
                    className={styles.select}
                    value={creativeTypeOptions}
                    onSelect={(value) =>
                      handleThirdFilter(value, "creativeType")
                    }
                    options={creativeTypeOptionsArray}
                    renderValue={(value) => creativeTypes[value]}
                  />
                ) : null}

                {filterValue === "campaigns" ? (
                  <SimpleMultiselect
                    className={styles.select}
                    options={campaignsList}
                    onSelect={(value) =>
                      handleThirdFilter(value, "campaignType")
                    }
                    value={filterCampaignsValue}
                  />
                ) : null}
                {filterValue === "adSets" ? (
                  <SimpleMultiselect
                    className={styles.select}
                    options={adSetsList}
                    onSelect={(value) => handleThirdFilter(value, "adsetsType")}
                    value={filterAdsetsValue}
                  />
                ) : null}

                <Button
                  color="primary"
                  className={styles.apply}
                  onClick={handleApplyFilter}
                >
                  Apply
                </Button>
              </div>
            )}

            {showMetrics && (
              <div className={styles.metricsMenu}>
                <SimpleMultiselect
                  className={styles.select}
                  withSearch
                  onSelect={handleMetricsOptions}
                  value={metricOptionsValues}
                  placeholder="Search metrics"
                  {...metricsOptions}
                />
              </div>
            )}

            <Stack direction="row" gutter="8" justify="flex-end">
              <ButtonGroup>
                <IconButton
                  variant="contained"
                  color={view === "card" ? "primary" : "secondary"}
                  onClick={() => onViewChange?.("card")}
                >
                  <Icon name="dashboard1" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color={view === "table" ? "primary" : "secondary"}
                  onClick={() => onViewChange?.("table")}
                >
                  <Icon name="menu" />
                </IconButton>
              </ButtonGroup>
              <Button color="primary" before={<Icon name="add" />}>
                Create report
              </Button>
            </Stack>
          </Stack>
        )}

        {appliedFiltersStrings.length ? (
          <div className={styles.filteredBy}>
            {appliedFiltersStrings.map((filter, index) => {
              if (!filter) return null
              return (
                <Chip
                  type="outlined"
                  color="primary"
                  endIcon={
                    <Icon
                      name="cross"
                      onClick={() => handleRemoveFilter(filter, index)}
                    />
                  }
                  className={styles.filterChip}
                  key={filter}
                >
                  {filter}
                </Chip>
              )
            })}
          </div>
        ) : null}
      </Box>
    )
  }
)

export default ReportControlPanel
