import { observable, action } from "mobx"
import { ID } from "@framework/types/types"
import creativeAnalyticsService, {
  ADSAnalyticsResponse,
} from "@services/creative-analytics"

class CreativeAnalyticsStore {
  @observable isLoading: boolean = false

  @observable error: string | null = null

  @observable adsCardsData: ADSAnalyticsResponse["data"] = {
    AdCreatives: [],
    Summary: {},
  }

  @action getADSCards = async (
    accountId: ID,
    datePair: { from: string; to: string }
  ) => {
    try {
      this.isLoading = true
      const response = await creativeAnalyticsService.getADSCards(
        accountId,
        datePair
      )
      this.adsCardsData = response.data.data ?? []
    } catch (error) {
      return "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return null
  }
}

export default CreativeAnalyticsStore
