import PMaxAssetGroupStore from "@store/pmax-asset-group/pmax-asset-group"
import SideBarMenuStore from "./sideBarMenu/sideBarMenu"
import AccountDashboardStore from "./account-dashboard/account-dashboard"
import AuthStore from "./auth/auth"
import AgencyStore from "./agency/agency"
import ClientStore from "./client/client"
import AccountStore from "./account/account"
import ManageProfileStore from "./manageProfile/manageProfile"
import CheckbookStore from "./checkbook/checkbook"
import AccountRulesStore from "./account-rules/account-rules"
import AccountLinkingStore from "./account-linking/account-linking"
import ManageCampaignsStore from "./manage-campaigns/manage-campaigns"
import ClientDashboardStore from "./client-dashboard/client-dashboard"
import ClientProfileStore from "./clientProfile/clientProfile"
import ClientCheckbookStore from "./client-checkbook/client-checkbook"
import ProductFeedStore from "./product-feed/product-feed"
import AppStore from "./app/app"
import SettingsStore from "./settings/settings.store"
import User from "./auth/user.store"
import ReportStore from "./reports/report.store"
import AdCopyStore from "./manageProfile/ad-copy-management.store"
import CreateCampaignsStore from "./opportunities/created-campaign.store"
import EditPMaxCampaignStore from "./manage-campaigns/edit-pmax-campaigns"
import AssetGroupsStore from "./manage-campaigns/asset-groups.store"
import LanguagesStore from "./manage-campaigns/languages.store"
import LocationsStore from "./manage-campaigns/locations.store"
import LocationsSuggestionsStore from "./manage-campaigns/locations-suggestions.store"
import CreativeAnalyticsStore from "./creative-analytics/creativeAnalytics"

export default class RootStore {
  // Put all other stores here
  appStore = new AppStore(this)

  userStore = new User(this)

  authStore = new AuthStore(this)

  accountStore = new AccountStore(this)

  clientStore = new ClientStore(this)

  agencyStore = new AgencyStore(this)

  sideBarMenuStore = new SideBarMenuStore(this)

  accountDashboardStore = new AccountDashboardStore(this)

  clientDashboardStore = new ClientDashboardStore(this)

  manageProfileStore = new ManageProfileStore(this)

  adCopyStore = new AdCopyStore(this)

  accountLinkingStore = new AccountLinkingStore(this.accountStore)

  checkbookStore = new CheckbookStore(this.accountStore)

  clientCheckbookStore = new ClientCheckbookStore(this.clientStore)

  rulesStore = new AccountRulesStore()

  manageCampaignStore = new ManageCampaignsStore()

  clientProfileStore = new ClientProfileStore(this.clientStore)

  productFeedStore = new ProductFeedStore(this)

  reports = new ReportStore(this)

  settings = new SettingsStore(this)

  createdCampaign = new CreateCampaignsStore()

  languagesStore = new LanguagesStore()

  locationsStore = new LocationsStore()

  locationSuggestionsStore = new LocationsSuggestionsStore(this)

  assetGroupsStore = new AssetGroupsStore()

  editPMaxCampaignStore = new EditPMaxCampaignStore()

  pMaxAssetGroupStore = new PMaxAssetGroupStore()

  creativeAnalyticsStore = new CreativeAnalyticsStore()
}
